import * as React from 'react';
import { Link } from 'react-router-dom';
import CheckUtils from '../../utilities/check-utils';
import { ProductCategory } from '../../infrastructure/http/modules/product/product.models';
import NavigationContext from '../../infrastructure/context/navigation/navigation.context';
import INavigationContext from '../../infrastructure/context/navigation/navigation.context.interface';
import TranslationsContext from '../../infrastructure/context/translations/translations-context';
import TranslationManager from '../../infrastructure/translation-manager';
import { Brand, TranslationKeys } from '../../infrastructure/const';
import ConfigurationContext from '../../infrastructure/context/configuration/configuration.context';
import IConfigurationContext from '../../infrastructure/context/configuration/configuration.context.interface';

export default class CategoryMenuTiles extends React.Component {

    renderMenu(categories: ProductCategory[]): JSX.Element | null {
        return (
            <TranslationsContext.Consumer>
                {(translationsContext) => (
                    <div className="category-top">
                        <div className="wrapper category-menu">
                            <h2 className="category-menu__title">
                                {new TranslationManager(translationsContext).getTranslation(TranslationKeys.MENUTILES_HEADER)}
                            </h2>
                            <div className="category-menu__list">
                                {!CheckUtils.isNullOrEmptyArray(categories) && categories.map(element => (
                                    this.renderMenuTile(element)
                                ))}
                                <ConfigurationContext.Consumer>
                                    {(configurationContext) => (
                                        this.renderBernafonRedirectTile(configurationContext)
                                    )}
                                </ConfigurationContext.Consumer>
                            </div>
                        </div>
                    </div>
                )}
            </TranslationsContext.Consumer>
        );
    }

    renderMenuTile(category: ProductCategory) {
        return (
            <article className="category-menu-tile" key={category.id}>
                <Link to={category.url}>
                    <div className="category-menu-tile__img">
                        <img src={category.image} alt={category.title} />
                    </div>
                </Link>

                <div className="">
                    <Link to={category.url} className="">
                        <h5 className="category-menu-tile__product-name">
                            {category.title}
                        </h5>
                    </Link>
                </div>
            </article>
        );
    }

    renderBernafonRedirectTile(configurationContext: IConfigurationContext): JSX.Element | null {
        if(configurationContext.brand !== Brand.Phonicear){
            return null;
        }

        const url = "https://webshop.bernafon.dk";
        const description = "Gå til Bernafons webshop";

        return (
            <article className="category-menu-tile" key="Bernafon">
                <a href={url} className="bernafon-logo-tile">
                    <div className="category-menu-tile__img">
                        <img src={"/images/Bernafon_logo_black_RGB.jpg"} alt={description} />
                    </div>
                </a>

                <div className="">
                    <a href={url}>
                        <h5 className="category-menu-tile__product-name">
                            {description}
                        </h5>
                    </a>
                </div>
            </article>
        );
    }

    render(): JSX.Element {
        return (
            <NavigationContext.Consumer>
                {(productCategoriesContext: INavigationContext) => (
                    <>
                        {this.renderMenu(productCategoriesContext.categories)}
                    </>
                )}
            </NavigationContext.Consumer>
        );
    }
}